'use es6';

import get from 'transmute/get';
import { VISITOR_SENDER } from '../constants/cmfSenderTypes';
import { COMMON_MESSAGE } from '../constants/messageTypes';
import { getSenderTypeForCMF } from './commonMessageFormatGetters';
import { FB_MESSENGER_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID } from '../constants/genericChannelIds';
import { getGenericChannelId } from '../operators/commonMessageGetters';
export const isCommonMessageFormat = message => {
  return get('@type', message) === COMMON_MESSAGE;
};
export const isFormCMF = message => {
  return isCommonMessageFormat(message) && getGenericChannelId(message) === FORMS_GENERIC_CHANNEL_ID;
};
export const isEmailCMF = message => {
  return isCommonMessageFormat(message) && getGenericChannelId(message) === EMAIL_GENERIC_CHANNEL_ID;
};
export const isChatMessage = message => {
  return isCommonMessageFormat(message) && !isEmailCMF(message);
};
export const isLiveChatPlainText = message => {
  return isCommonMessageFormat(message) && getSenderTypeForCMF(message) === VISITOR_SENDER && getGenericChannelId(message) === LIVE_CHAT_GENERIC_CHANNEL_ID;
};
export const isForwardableEmailOrFormCMF = message => {
  return isCommonMessageFormat(message) && (isEmailCMF(message) || isFormCMF(message));
};
export const isFacebookChannel = message => isCommonMessageFormat(message) && getGenericChannelId(message) === FB_MESSENGER_GENERIC_CHANNEL_ID;